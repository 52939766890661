import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  ClockIcon,
  ArrowRightCircleIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";

import logo from "../images/logo-full.png";
import logoWhite from "../images/logo-white.png";
import Header from "../components/Header/Header";
import product1 from "../images/product-1.png";
import { navigate } from "gatsby";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import axios from "axios";
import { getApplications } from "../helpers/courses";
import { Footer } from "../components/Footer/Footer";

import { useSelector } from "react-redux";
import { RootState } from "../reducers/rootReducer";

// import Tippy from '@tippyjs/react/headless';

const Learning = () => {
  const loginElement: any = useSelector((state: RootState) => state.loginSlice);
  const userName =
    typeof window !== "undefined" && localStorage.getItem("name");
  const token =
    typeof window !== "undefined" && localStorage.getItem("access_token");
  const user =
    typeof window !== "undefined" &&
    JSON.parse(localStorage.getItem("user") || "{}");
  const [enrolls, setEnrolls] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [enrollsCompleted, setEnrollsCompleted] = useState<any>([]);
  const [spec, setSpec] = useState<any>([]);
  const [own, setOwn] = useState<any>([]);
  const [office, setOffice] = useState<any>([]);
  const [ownCompleted, setOwnCompleted] = useState<any>([]);
  const [officeCompleted, setOfficeCompleted] = useState<any>([]);
  const [applications, setApplications] = useState([]);
  const [signed, setSigned] = useState(false);

  const [link, setLink] = useState("");

  const [upcoming, setUpcoming] = useState(true);
  const [progress, setProgress] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [achievements, setAchievements] = useState(false);

  const { items }: any = useSelector((state: RootState) => state.loginSlice);

  useEffect(() => {
    if (items.status) {
      setSigned(true);
    } else {
      navigate("/");
    }
  }, [items]);

  const getEnrolls = async (status: string) => {
    setIsLoading(true);
    var config = {
      method: "get",
      url: process.env.API_URL + `/api/enrollment/?status=${status}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    await axios(config)
      .then(function (response) {
        // const rolls: any = [];
        // const coming: any = [];
        // response.data.data.map( (item: any) => {
        //   if(item.status === "PENDING") {
        //     coming.push(item)
        //   } else {
        //     rolls.push(item)
        //   }
        // })
        // setEnrolls(rolls);
        // setSpec(coming);
        const rolls: any = [];
        const coming: any = [];
        const completed: any = [];
        response.data.data.map((item: any) => {
          if (item.status === "PENDING") {
            coming.push(item);
          } else if (item.status === "COMPLETED") {
            rolls.push(item);
          } else {
            completed.push(item);
          }
        });
        setEnrolls(rolls);
        setSpec(coming);
        setEnrollsCompleted(completed);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log("****** this error: ", error);
      });
  };

  const getLink = async (uuid: string) => {
    let element: any;

    const data = JSON.stringify({
      courseUuid: uuid,
    });

    const config = {
      method: "post",
      url: process.env.API_URL + "/api/enrollment/sso",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios(config)
      .then(function (response) {
        typeof window !== "undefined" &&
          window.open(response.data.loginLink, "_blank");
      })
      .catch(function (error) {
        console.log(error);
      });

    return element;
  };

  const resApplications = async () => {
    const applications = await getApplications();
    setApplications(applications);
  };

  useEffect(() => {
    if (token !== null) {
      getEnrolls("pending");
      resApplications();
    }
  }, [token]);

  useEffect(() => {
    if (enrolls.length) {
      // console.log("the enrolls: **** ", enrolls);
      let ownRolls: any = [];
      let offRolls: any = [];
      enrolls.map((item: any) => {
        if (item.enrollmentId !== null) {
          offRolls.push(item);
        } else {
          ownRolls.push(item);
        }
      });
      setOwn(ownRolls);
      setOffice(offRolls);
    }
  }, [enrolls]);
  useEffect(() => {
    if (enrollsCompleted.length) {
      // console.log("the enrolls: **** ", enrolls);
      let ownRolls: any = [];
      let offRolls: any = [];
      enrolls.map((item: any) => {
        if (item.enrollmentId !== null) {
          offRolls.push(item);
        } else {
          ownRolls.push(item);
        }
      });
      setOwnCompleted(ownRolls);
      setOfficeCompleted(offRolls);
    }
  }, [enrollsCompleted]);
  const handleChange = (status: string) => {
    if (status === "upcoming") {
      getEnrolls("pending");
      setUpcoming(true);
      setProgress(false);
      setCompleted(false);
      setAchievements(false);
    } else if (status === "progress") {
      getEnrolls("completed");
      setUpcoming(false);
      setProgress(true);
      setCompleted(false);
      setAchievements(false);
    } else if (status === "completed") {
      getEnrolls("ready_to_badge");
      setUpcoming(false);
      setProgress(false);
      setCompleted(true);
      setAchievements(false);
    } else {
      getEnrolls("badged");
      setUpcoming(false);
      setProgress(false);
      setCompleted(false);
      setAchievements(true);
    }
  };

  return (
    <Layout signed={signed}>
      <div className="bg-slate-50 min-h-screen relative pb-[24px]">
        {/* <Header isSignIn={signed} /> */}
        <div className="bg-banner-learning bg-contain bg-no-repeat bg-top container mx-5 lg:mx-auto  ">
          <div className="container  mx-auto">
            <section className="container  mx-auto">
              <div className="pt-10 mb-10 flex xl:items-center justify-between flex-col xl:flex-row">
                <h3 className="text-[48px] xl:text-[68px] 2xl:text-[78px] mb-6 lg:mb-0 lg:pl-4">
                  Your <span className="ff-cg--semibold">Learning</span>
                </h3>
                <div className="flex items-center overflow-x-auto">
                  <div
                    className="flex items-center flex-col cursor-pointer"
                    onClick={() => handleChange("upcoming")}
                  >
                    <p
                      className={`pb-3 px-10 text-base lg:text-xl   whitespace-nowrap duration-200 ${
                        upcoming ? "ff-cg--semibold " : ""
                      }`}
                    >
                      Upcoming
                    </p>
                    <span
                      className={`border-b border-solid w-full duration-200 ${
                        upcoming
                          ? "border-[#da1a32] border-2"
                          : "border-[#222222] border-b-2"
                      }`}
                    ></span>
                  </div>
                  <div
                    className="flex items-center flex-col cursor-pointer"
                    onClick={() => handleChange("progress")}
                  >
                    <p
                      className={`pb-3 px-10  text-base  lg:text-xl    whitespace-nowrap duration-200 ${
                        progress ? "ff-cg--semibold " : ""
                      }`}
                    >
                      In Progress
                    </p>
                    <span
                      className={`border-b border-solid w-full duration-200 ${
                        progress
                          ? "border-[#da1a32] border-2"
                          : "border-[#222222] border-b-2"
                      }`}
                    ></span>
                  </div>
                  <div
                    className="flex items-center flex-col cursor-pointer"
                    onClick={() => handleChange("completed")}
                  >
                    <p
                      className={`pb-3 px-10  text-base lg:text-xl x2xl:text-3l  whitespace-nowrap duration-200 ${
                        completed ? "ff-cg--semibold " : ""
                      }`}
                    >
                      Completed
                    </p>
                    <span
                      className={`border-b border-solid w-full duration-200 ${
                        completed
                          ? "border-[#da1a32] border-2"
                          : "border-[#222222] border-b-2"
                      }`}
                    ></span>
                  </div>
                  <div
                    className="flex items-center flex-col cursor-pointer"
                    onClick={() => handleChange("achievements")}
                  >
                    <p
                      className={`pb-3 px-10 text-base  lg:text-xl  whitespace-nowrap duration-200 ${
                        achievements ? "ff-cg--semibold " : ""
                      }`}
                    >
                      Achievements
                    </p>
                    <span
                      className={`border-b border-solid w-full duration-200 ${
                        achievements
                          ? "border-[#da1a32] border-2"
                          : "border-[#222222] border-b-2"
                      }`}
                    ></span>
                  </div>
                </div>
              </div>
            </section>

            {/* Upcoming */}
            {upcoming && (
              <section className="container  mx-auto md:mb-20 mb-10 shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_-4px_6px_rgba(17,17,26,0.1)] rounded-xl">
                <div className="rounded-md bg-white shadow-lg p-[15px] md:p-[30px] pb-10 md:pb-16">
                  <h3 className="text-[20px] md:text-[40px] mb-6 font-medium ff-cg--semibold">
                    Upcoming
                  </h3>
                  {spec.length ? (
                    <>
                      {spec.map((item: any, index: number) => {
                        return (
                          <>
                            {item.course !== null &&
                              item.course !== undefined && (
                                <div
                                  className="rounded-xl bg-white flex shadow-lg relative items-center flex-col md:flex-row mb-6"
                                  key={index}
                                >
                                  <div className="relative w-full md:w-[200px]">
                                    <div className="before:bg-black before:absolute before:top-0 before:bottom-0 before:left-0 before:right-0 before:rounded-xl before:opacity-50"></div>
                                    <img
                                      className="w-full md:w-[200px] object-cover h-[100px] lg:h-[120px] rounded-xl bg-slate-300"
                                      src={item.course.imgUrl}
                                      alt=""
                                    />
                                  </div>
                                  <div className="p-[15px] md:pl-8 md:p-5 md:flex md:items-center md:justify-between w-full">
                                    <div>
                                      <h4 className="text-[16px] lg:text-[26px] ff-cg--semibold leading-none mb-[10px]">
                                        {item.course.title}
                                      </h4>
                                      <div className="flex items-center lex-wrap">
                                        <span className="flex items-center border border-red-200 rounded-full px-[10px] mr-4 whitespace-nowrap">
                                          <span className="ff-cg--semibold text-[12px]">
                                            {item.course.type.name ||
                                              "Certificate"}
                                          </span>
                                        </span>
                                        <span className="flex items-center border border-red-200 rounded-full pl-[3px] pr-[10px] whitespace-nowrap mr-4">
                                          <ClockIcon className="h-4 w-4 mr-[6px]" />
                                          <span className="ff-cg--semibold text-[12px]">
                                            {item.course.duration}
                                          </span>
                                        </span>
                                        <span className="flex items-center border border-red-200 rounded-full pl-[3px] pr-[10px] whitespace-nowrap mr-4">
                                          <ClockIcon className="h-4 w-4 mr-[6px]" />
                                          <span className="ff-cg--semibold text-[12px]">
                                            {item.preferredStart}
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                    <button className="w-full lg:w-fit flex flex-col items-center justify-between border solid border-black py-3 px-[16px] rounded-2xl md:ml-[20px] mt-4 md:mt-0 relative">
                                      <Tippy
                                        content={
                                          <>
                                            <h3 className="text-center font-bold mb-2 pt-1 text-sm text-gray-900">
                                              We're configurin the access to
                                              your courses
                                            </h3>
                                            <p className="text-center pb-2 text-xs text-gray-900">
                                              We are currently working to have
                                              everything ready for you. You will
                                              start your learning path soon.
                                            </p>
                                          </>
                                        }
                                      >
                                        <span className="absolute text-xs top-0 right-0 bg-yellow-500 border-2 border-gray-800rounded-full w-4 h-4 flex justify-center items-center">
                                          i
                                        </span>
                                      </Tippy>
                                      <span className="leading-none text-[12px]">
                                        Status
                                      </span>
                                      <span className="ff-cg--semibold text-[12px] leading-none">
                                        Pending
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              )}
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <div className="p-8">
                      <p className="font-bold text-3xl text-center w-full">
                        {isLoading
                          ? "Loading..."
                          : "No Courses found for this section"}
                      </p>
                    </div>
                  )}
                </div>
              </section>
            )}
            {/* In progress */}
            {progress && (
              <section className="container  mx-auto md:mb-20 mb-10">
                {office.length ? (
                  <div className="rounded-md bg-white shadow-lg p-[15px] md:p-[30px] pb-10 md:pb-16 mb-8">
                    <div className="flex flex-col md:flex-row items-start md:items-center md:justify-between mb-6">
                      <h3 className="text-[20px] lg:text-[30px] font-medium">
                        From your Organization
                      </h3>
                      {/* <img className="object-cover h-[40px]" src={logo} alt="" /> */}
                    </div>
                    {office.length ? (
                      <>
                        {office.map((item: any, index: number) => {
                          return (
                            <div
                              className="rounded-xl bg-white flex shadow-lg relative items-center flex-col md:flex-row mb-8"
                              key={index}
                            >
                              <div className="relative w-full md:w-[200px]">
                                <div className="before:bg-black before:absolute before:top-0 before:bottom-0 before:left-0 before:right-0 before:rounded-xl before:opacity-50"></div>
                                <img
                                  className="w-full md:w-[200px] object-cover h-[100px] lg:h-[120px] rounded-xl bg-slate-300"
                                  src={item.course.imgUrl}
                                  alt=""
                                />
                              </div>
                              <div className="p-[15px] md:pl-8 md:p-5 md:flex md:items-center md:justify-between w-full">
                                <div>
                                  <h4 className="text-[16px] lg:text-[26px] ff-cg--semibold leading-none mb-[10px]">
                                    {item.course.title}
                                  </h4>
                                  <div className="flex items-center lex-wrap">
                                    <span className="flex items-center border border-red-200 rounded-full px-[10px] mr-4 whitespace-nowrap">
                                      <span className="ff-cg--semibold text-[12px]">
                                        {item.course.type.name || "Certificate"}
                                      </span>
                                    </span>
                                    <span className="flex items-center border border-red-200 rounded-full pl-[3px] pr-[10px] whitespace-nowrap mr-4">
                                      <ClockIcon className="h-4 w-4 mr-[6px]" />
                                      <span className="ff-cg--semibold text-[12px]">
                                        {item.course.duration}
                                      </span>
                                    </span>
                                    <span className="flex items-center border border-red-200 rounded-full pl-[3px] pr-[10px] whitespace-nowrap mr-4">
                                      <ClockIcon className="h-4 w-4 mr-[6px]" />
                                      <span className="ff-cg--semibold text-[12px]">
                                        {item.course.type.name}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                                <button
                                  onClick={() => getLink(item.course.uuid)}
                                  className="w-full lg:w-fit flex items-center justify-between border border-[#222222] py-[14px] px-[16px] rounded-2xl mt-4 md:mt-0"
                                >
                                  <span className="ff-cg--semibold mr-[20px]">
                                    Continue
                                  </span>
                                  <ArrowRightCircleIcon className="h-6 w-6" />
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <div className="p-8">
                        <p className="font-bold text-3xl text-center w-full">
                          {isLoading
                            ? "Loading..."
                            : "No Courses found for this section"}
                        </p>
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}

                <div className="rounded-md bg-white shadow-lg p-[15px] md:p-[30px] pb-10 md:pb-16">
                  <h3 className="text-[20px] md:text-[40px] mb-6 font-medium	ff-cg--semibold">
                    From you
                  </h3>

                  {own.length ? (
                    <>
                      {own.map((item: any, index: number) => {
                        if (
                          item.course.sponsor.title === "MindEdge" &&
                          item.course.children.length >= 1
                        ) {
                          let children: any = [...item.course.children];
                          // console.log("children: *** ", children);

                          return (
                            <div
                              className="rounded-xl bg-white  shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)] relative mb-8 p-4"
                              key={index}
                            >
                              <div className="grid gap-6 lg:gap-10 grip-cols-4 lg:grid-cols-12">
                                <div className="col-span-3">
                                  <div className="relative w-full md:w-[150px] md:h-[100px] mb-5 hidden md:block">
                                    <div className="before:bg-black before:absolute before:top-0 before:bottom-0 before:left-0 before:right-0 before:rounded-xl before:opacity-50"></div>
                                    <img
                                      className="w-full md:w-[150px] md:h-[100px] object-cover rounded-xl bg-slate-300"
                                      src={item.course.imgUrl}
                                      alt=""
                                    />
                                  </div>
                                  <h4 className="text-[26px] ff-cg--semibold leading-[32px] mb-[10px]">
                                    {item.course.title}
                                  </h4>
                                  <p className="mb-3 hidden md:block">
                                    {item.course.description}
                                  </p>
                                  <div className="">
                                    <span className="flex items-center border border-red-200 rounded-full px-[10px] mr-4 whitespace-nowrap py-1  mb-2">
                                      <ClockIcon className="h-4 w-4 mr-[6px]" />
                                      <span className="ff-cg--semibold text-[12px]">
                                        {item.course.type.name}
                                      </span>
                                    </span>
                                    {/* <span className="flex items-center border border-red-200 rounded-full px-[10px] mr-4 whitespace-nowrap py-1 ">
                                  <ClockIcon className="h-4 w-4 mr-[6px]" />
                                  <span className="ff-cg--semibold text-[12px]">
                                    Duration: {item.course.duration}
                                  </span>
                                </span> */}
                                  </div>
                                </div>
                                <div
                                  className={`col-span-9 ${
                                    children.length > 1 ? "bg-[#f5f5f5]" : ""
                                  } `}
                                >
                                  {children.map((item: any, index: number) => {
                                    return (
                                      <div
                                        className="bg-[#f5f5f5] md:p-4 rounded-xl mb-4"
                                        key={index}
                                      >
                                        <div className="bg-[#222222] rounded-t-xl px-4 p-2 md:hidden flex items-center justify-between">
                                          <p className="ff-cg--semibold text-white">
                                            Courses
                                          </p>
                                          <ChevronDownIcon className="h-4 w-4 mr-[6px] text-white" />
                                        </div>
                                        <div className="flex items-center border-b-[1px] border-gray-300  px-4 md:px-0 py-4 justify-between ">
                                          <div className="flex flex-col md:flex-row md:items-center justify-between w-7/12">
                                            <div className="mb-2">
                                              <h3 className="text-[16px] lg:text-[20px] ff-cg--semibold">
                                                {item.title}
                                              </h3>
                                              <p className="hidden md:block">
                                                {item.description}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="flex items-center flex-wrap md:ml-8">
                                            <span className="flex items-center border border-red-200 rounded-full px-[10px] mr-4 whitespace-nowrap">
                                              <ClockIcon className="h-4 w-4 mr-[6px]" />
                                              <span className="ff-cg--semibold text-[12px]">
                                                {item.duration}
                                              </span>
                                            </span>
                                          </div>
                                          <button className="flex items-center justify-center md:justify-between border border-[#222222] py-[10px] px-[16px] rounded-full">
                                            <span className="flex">
                                              <span
                                                className="ff-cg--semibold hidden md:block md:mr-[20px] whitespace-nowrap"
                                                onClick={() =>
                                                  getLink(item.uuid)
                                                }
                                              >
                                                Go to Class
                                              </span>
                                              <ArrowRightCircleIcon className="h-6 w-6" />
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              className="rounded-xl bg-white flex shadow-lg relative items-center flex-col md:flex-row mb-8"
                              key={index}
                            >
                              <div className="relative w-full md:w-[200px]">
                                <div className="before:bg-black before:absolute before:top-0 before:bottom-0 before:left-0 before:right-0 before:rounded-xl before:opacity-50"></div>
                                <img
                                  className="w-full md:w-[200px] object-cover h-[100px] lg:h-[120px] rounded-xl bg-slate-300"
                                  src={item.course.imgUrl}
                                  alt=""
                                />
                              </div>
                              <div className="p-[15px] md:pl-8 md:p-5 md:flex md:items-center md:justify-between w-full">
                                <div>
                                  <h4 className="text-[16px] lg:text-[26px] ff-cg--semibold leading-none mb-[10px]">
                                    {item.course.title}
                                  </h4>
                                  <div className="flex items-center lex-wrap">
                                    <span className="flex items-center border border-red-200 rounded-full px-[10px] mr-4 whitespace-nowrap">
                                      <ClockIcon className="h-4 w-4 mr-[6px]" />
                                      <span className="ff-cg--semibold text-[12px]">
                                        Access for: {item.course.access}
                                      </span>
                                    </span>
                                    <span className="flex items-center border border-red-200 rounded-full pl-[3px] pr-[10px] whitespace-nowrap mr-4">
                                      <ClockIcon className="h-4 w-4 mr-[6px]" />
                                      <span className="ff-cg--semibold text-[12px]">
                                        Duration: {item.course.duration}
                                      </span>
                                    </span>
                                    <span className="flex items-center justify-center border max-w-[133px] border-red-200 rounded-full pl-[3px] pr-[10px] whitespace-nowrap mr-4">
                                      {/* <ClockIcon className="h-4 w-4 mr-[6px]" /> */}
                                      <span className="ff-cg--semibold text-[12px]">
                                        {item.course.type.name ===
                                        "Learning Path"
                                          ? "Certificate"
                                          : item.course.type.name}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                                {item.ssoRequired ? (
                                  <button className="w-full lg:w-fit flex items-center justify-between border border-[#222222] py-[14px] px-[16px] rounded-2xl mt-4 md:mt-0">
                                    <span
                                      className="flex"
                                      onClick={() => getLink(item.course.uuid)}
                                    >
                                      <span className="ff-cg--semibold mr-[20px]">
                                        Continue
                                      </span>
                                      <ArrowRightCircleIcon className="h-6 w-6" />
                                    </span>
                                  </button>
                                ) : (
                                  <button className="w-full lg:w-fit flex items-center justify-between border border-[#222222] py-[14px] px-[16px] rounded-2xl mt-4 md:mt-0">
                                    <span className="flex">
                                      <span className="ff-cg--semibold">
                                        Go to the vendors website
                                      </span>
                                      {/* <ArrowRightCircleIcon className="h-6 w-6" /> */}
                                    </span>
                                  </button>
                                )}
                              </div>
                            </div>
                          );
                        }
                      })}
                    </>
                  ) : (
                    <div className="p-8">
                      <p className="font-bold text-3xl text-center w-full">
                        {isLoading
                          ? "Loading..."
                          : "No Courses found for this section"}
                      </p>
                    </div>
                  )}
                  {/* <div className="rounded-xl bg-white flex shadow-lg relative items-center flex-col md:flex-row">
                <div className="relative w-full md:w-[200px]">
                  <div className="before:bg-black before:absolute before:top-0 before:bottom-0 before:left-0 before:right-0 before:rounded-xl before:opacity-50"></div>
                  <img className="w-full md:w-[200px] object-cover h-[100px] lg:h-[120px] rounded-xl bg-slate-300" src={product1} alt="" />
                </div>
                <div className="p-[15px] md:pl-8 md:p-5 md:flex md:items-center md:justify-between w-full">
                  <div>
                    <h4 className="text-[16px] lg:text-[26px] ff-cg--semibold leading-none mb-[10px]">Introduction to Cybersecurity Tools & Cyber Attacks</h4>
                    <div className="flex items-center lex-wrap">
                      <span className="flex items-center border border-red-200 rounded-full px-[10px] mr-4 whitespace-nowrap">
                        <span className="ff-cg--semibold text-[12px]">Cybersecurity</span>
                      </span>
                      <span className="flex items-center border border-red-200 rounded-full pl-[3px] pr-[10px] whitespace-nowrap mr-4">
                        <ClockIcon className="h-4 w-4 mr-[6px]" />
                        <span className="ff-cg--semibold text-[12px]">4 Course</span>
                      </span>
                      <span className="flex items-center border border-red-200 rounded-full pl-[3px] pr-[10px] whitespace-nowrap mr-4">
                        <ClockIcon className="h-4 w-4 mr-[6px]" />
                        <span className="ff-cg--semibold text-[12px]">Course</span>
                      </span>
                    </div>
                  </div>
                  <button className="w-full lg:w-fit flex items-center justify-between border border-[#222222] py-[14px] px-[16px] rounded-2xl mt-4 md:mt-0">
                    <span className="ff-cg--semibold mr-[20px]">Continue</span>
                    <ArrowRightCircleIcon className="h-6 w-6" />
                  </button>
                </div>
              </div> */}
                </div>
              </section>
            )}

            {/* Completed */}
            {completed && (
              <section className="container  mx-auto md:mb-20 mb-10">
                <div className="rounded-md bg-white shadow-lg p-[15px] md:p-[30px] pb-10 md:pb-16">
                  <h3 className="text-[20px] md:text-[40px] mb-6 font-medium ff-cg--semibold">
                    Completed
                  </h3>

                  {enrollsCompleted.length > 0 ? (
                    <>
                      {enrollsCompleted.map((item: any) => (
                        <div className="rounded-xl bg-white flex shadow-lg relative items-center flex-col md:flex-row my-4">
                          <div className="relative w-full md:w-[200px]">
                            <div className="before:bg-black before:absolute before:top-0 before:bottom-0 before:left-0 before:right-0 before:rounded-xl before:opacity-50"></div>
                            <img
                              className="w-full md:w-[200px] object-cover h-[100px] lg:h-[120px] rounded-xl bg-slate-300"
                              src={item?.course?.imgUrl}
                              alt=""
                            />
                          </div>
                          <div className="p-[15px] md:pl-8 md:p-5 md:flex md:items-center md:justify-between w-full">
                            <div>
                              <h4 className="text-[16px] lg:text-[26px] ff-cg--semibold leading-none mb-[10px]">
                                {item.course.title}
                              </h4>
                              <div className="flex items-center lex-wrap">
                                <span className="flex items-center border border-red-200 rounded-full px-[10px] mr-4 whitespace-nowrap">
                                  <span className="ff-cg--semibold text-[12px]">
                                    {item.course.type.name || "Certificate"}
                                  </span>
                                </span>
                                <span className="flex items-center border border-red-200 rounded-full pl-[3px] pr-[10px] whitespace-nowrap mr-4">
                                  <ClockIcon className="h-4 w-4 mr-[6px]" />
                                  <span className="ff-cg--semibold text-[12px]">
                                    {item?.course?.children?.length} Course
                                  </span>
                                </span>
                                <span className="flex items-center border border-red-200 rounded-full pl-[3px] pr-[10px] whitespace-nowrap mr-4">
                                  <ClockIcon className="h-4 w-4 mr-[6px]" />
                                  <span className="ff-cg--semibold text-[12px]">
                                    {item?.course?.type?.name}
                                  </span>
                                </span>
                              </div>
                            </div>
                            <button className="w-full lg:w-fit flex items-center justify-between border border-[#222222] py-[14px] px-[16px] rounded-2xl mt-4 md:mt-0 md:w-36">
                              <span className="ff-cg--semibold mr-[20px]">
                                Accept Badge
                              </span>
                              <ArrowRightCircleIcon className="h-6 w-6" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="p-8">
                      <p className="font-bold text-3xl text-center w-full">
                        {isLoading
                          ? "Loading..."
                          : "No Courses found for this section"}
                      </p>
                    </div>
                  )}
                </div>
              </section>
            )}

            {/* Archievements */}
            {achievements && (
              <section className="container  mx-auto md:mb-20 mb-10">
                <div className="rounded-md bg-white shadow-lg p-[15px] md:p-[30px] pb-10 md:pb-16">
                  <h3 className="text-[20px] md:text-[40px] mb-6 font-medium ff-cg--semibold">
                    Certificates and Badges (TBD)
                  </h3>
                  <div className="p-8">
                    <p className="font-bold text-3xl text-center w-full">
                      No Courses found for this section
                    </p>
                  </div>
                </div>
              </section>
            )}
          </div>
          {/* footer */}
          <Footer />
        </div>
      </div>
    </Layout>
  );
};

export default Learning;
export { Head } from "../components/Layout/Head";
